import ProjectService from '@/services/API/project.service';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import StarRating from '@/components/StarRating/StarRating.vue';

import CreativeService from '@/services/API/creative.service';
import UserService from '@/services/API/user.service';
import DecisionService from '@/services/API/decision.service';
import CommonConst from '@/constants/CommonConst';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import ProcessingConst from '@/constants/ProcessingConst';
import socketClient from '../../../services/SOCKET';
import emitSoundMixin from '@/mixin/emitSound';

import { mapState } from 'vuex';
import moment from 'moment';

export default {
	components: {
		BottomButton,
		StarRating,
	},
	mixins: [emitSoundMixin],
	data() {
		return {
			middleContent: ' 様 ／ ',
			totalZoomMettingTime: '1 時間 09 分',
			rating: 0,
			showButton: false,

			isCreatedAccount: true,
		};
	},
	computed: {
		// Get data from store
		...mapState([
			'preview',
			'clientMode',
			'projectId',
			'token',
			'userId',
			'projectInfo',
			'creatorInfo',
			'clientInfo',
			'managementMasterInfo',
			'appLogo',
			'appLogoPlain',
		]),

		isProjectFinished() {
			return this.projectInfo.id && this.projectInfo['client_finished'] === 1;
		},
		showContent() {
			return !!this.projectInfo.id;
		},
	},

	watch: {
		projectInfo: {
			handler(newProjectInfo) {
				if (newProjectInfo?.id) {
					this.rating =
						newProjectInfo['client_rating'] > 0 ? newProjectInfo['client_rating'] : 0;

					if (newProjectInfo['status'] !== ProcessingConst.FREEZE_STATUS) {
						if (!this.clientMode && !this.preview) {
							this.showButton = true;
						}
					}

					let hour =
						newProjectInfo['zoom_session_duration'] < 60
							? 0
							: Math.floor(newProjectInfo['zoom_session_duration'] / 60);
					let minute = Math.round(newProjectInfo['zoom_session_duration']) % 60;
					this.totalZoomMettingTime = hour + ' 時間 ' + minute + ' 分';
				}
			},
			immediate: true,
		},
	},

	methods: {
		async onClick() {
			this.$store.commit('setIsAppProcessing', true);
			this.emitMixinStartingSound();
			const startStatus = {
				only_decision: 0,
				client_start: 1,
			};
			await this.updateProject(this.projectId, startStatus);
			this.$emit('on-send-start-info', true);

			socketClient.send('data_transfer', {
				data: {
					user_id: this.creatorInfo.id,
					event_name: 'update_project_status',
				},
				room: 'user' + this.creatorInfo.id,
			});

			// this.sendNotification();

			await this.direct();

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'client-start',
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
		},
		async onClickDecision() {
			this.$store.commit('setIsAppProcessing', true);
			this.emitMixinStartingSound();
			let response = await DecisionService.get(this.projectId);
			if (!response || response.status !== 200) {
				throw 'Get Decision failed';
			}
			if (response.data['project_id'] !== this.projectId) {
				await this._createNewDecision();
			}
			const startStatus = {
				only_decision: 1,
				client_start: 1,
				status: 2,
				project_start_date: new Date().toDateString(),
			};
			await this._updateProject(this.projectId, startStatus);
			this.$emit('on-send-start-info', true);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.creatorInfo.id,
					event_name: 'update_project_status',
				},
				room: 'user' + this.creatorInfo.id,
			});

			// this.sendNotification();

			this.$router.push('decision');

			this.$store.commit('setIsAppProcessing', false);
		},
		async _createNewDecision() {
			let decisionData = {
				project_id: this.projectId,
				image_list: [],
				update_user: this.userId,
			};

			let response = await DecisionService.post({
				doc_content: decisionData,
			});

			if (!response || response.status !== 200) {
				throw 'Create Decision failed';
			}

			console.log('%c Create Decision successfully', 'color: green');
		},

		async direct() {
			const endPoint = await this.getClientUrlEndPoint(this.projectInfo);
			console.log({ endPoint });
			this.$router.push(endPoint);
		},

		sendNotification() {
			moment.locale('ja');
			let emailContentHeader =
				this.clientInfo['client_name'] + '様' + '/' + this.projectInfo['project_name'];
			let emailContentFooter = 'スタートしました。';
			let emailContent = `${emailContentHeader}\n${emailContentFooter}`;
			let mailContents = {
				destinations: [
					//replace with your email for testing
					this.clientInfo['email_address'],
				],
				sender: this.managementMasterInfo['sender_email_address'],
				subject:
					'【COMODE】' +
					'[' +
					this.projectInfo['project_name'] +
					'] ' +
					this.projectInfo['project_name'] +
					'スタート',
				template: 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: 'schedule',
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					sendMailDate: moment().format('LLL'),
					emailContent: emailContent,
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: `${this.projectInfo['process_client_url']}schedule`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
			/* eslint-disable no-mixed-spaces-and-tabs */
			let notifyContent =
				this.projectInfo['notify_method'] == 1
					? toLineMessageTemplate({
							title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
							message: `${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}\nスタートしました。`,
							currentUrl: `${this.projectInfo['process_client_url']}schedule`,
					  })
					: mailContents;
			sendNotify(notifyContent, this.projectInfo['notify_method'], [
				this.clientInfo['id'],
			]);
		},

		onOpenChatRoomClick() {
			this.$emit('on-open-chat-room');
		},

		onRegisterButtonClick() {
			let routeData = this.$router.resolve({
				path: '/adminApp/registerclient',
				query: {
					register_name: this.clientInfo['client_name'],
					email_address: this.clientInfo['email_address'],
					phone_number: this.clientInfo['phone_number']
						? this.clientInfo['phone_number']
						: '',
					account_type: 'client',
					client_id: this.clientInfo['id'],
					responsible_name: this.clientInfo['responsible_name']
						? this.clientInfo['responsible_name']
						: '',
				},
			});

			window.open(routeData.href, '_blank');
		},

		async onRating(rating) {
			this.$store.commit('setIsAppProcessing', true);

			await this._updateProject(this.projectId, { client_rating: rating });

			let projectInfo = { ...this.projectInfo, client_rating: rating };
			this.$store.commit('setProjectInfo', projectInfo);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Update NEXT processing screen status
		 * @param {Integer} projectId
		 * @param {Object} content
		 */
		async _updateProject(projectId, content) {
			let response = await ProjectService.update(projectId, content);
			if (response.status !== 200) {
				throw 'Update project failed';
			}
			console.log('Update project successfully');
		},

		/**
		 * Update NEXT processing screen status
		 * @param {Integer} projectId
		 * @param {Object} startStatus
		 */
		async updateProject(projectId, startStatus) {
			console.log('Update status');
			let response = await ProjectService.update(projectId, startStatus);
			if (response.status !== 200) {
				throw 'Update status failed';
			}
			console.log('Update status successfully');
		},

		/**
		 * Check cretive is counting down, if true return proposal endpoint
		 * @param {Object} project Project objects
		 * @returns ClientApp url endPoint when creative is couting down
		 */
		async getClientUrlEndPoint(project) {
			let endPoint = ProcessingConst.STATUS_LIST[project.status].value;

			if (project.status !== CommonConst.SCREEN_ID.PROPOSAL) return endPoint;

			let creative = await this._getCreativeData(project.id);
			if (creative.status !== CommonConst.SCREEN_STATUS.COUNTING_DOWN) return endPoint;

			return ProcessingConst.STATUS_LIST[CommonConst.SCREEN_ID.CREATIVE].value;
		},

		/**
		 * Get creative Data from API
		 * @param {Integer} projectId
		 */
		async _getCreativeData(projectId) {
			let response = await CreativeService.get(projectId);
			if (response.status !== 200) {
				throw 'Get creative Data failed!';
			}
			return response.data;
		},
		async getClientUserInfo() {
			let response = await UserService.get(this.userId);
			if (response.status !== 200) {
				throw 'Get client user info failed!';
			}
			if (Object.keys(response.data).length === 0) {
				this.isCreatedAccount = false;
			} else {
				this.isCreatedAccount = true;
			}
		},
	},
	mounted() {
		let interval = setInterval(() => {
			if (this.userId) {
				this.getClientUserInfo();
				clearInterval(interval);
			}
		});

		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'client-start':
					if (!!this.preview || this.clientMode) {
						this.emitMixinStartingSound();
						this.$emit('on-send-start-info', true);

						await this.direct();
					}
					break;

				default:
					break;
			}
		});
	},
};
